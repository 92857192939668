import React from 'react';
import PropTypes from 'prop-types';

const Alert = React.forwardRef(({ message, className, ...props }, ref) => {
  return (
    <div
      className={`flex items-center h-10 w-full rounded-md border border-red-400 bg-red-100 px-3 py-2 text-sm text-red-700 ${className}`}
      ref={ref}
      {...props}
    >
      <span>{message}</span>
    </div>
  );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string
};

export { Alert };
