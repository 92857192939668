import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from '../components/ui/alert';
import { useAuth } from '../contexts/AuthContext';
import Chatbox from '../components/Chatbox';
import Header from '../components/Header';
import Dashboard from '../components/Dashboard';
import UnderTheHood from '../components/UnderTheHood';

export default function HomePage() {
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();
  const [isUnderTheHoodVisible, setIsUnderTheHoodVisible] = useState(false);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const navigate = useNavigate();

  const getWeekStart = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };
  const [currentWeek, setCurrentWeek] = useState(getWeekStart(new Date()));

  const handleChatToggle = () => {
    setIsChatVisible(!isChatVisible);
    if (!isChatVisible) {
      setHasNewMessage(false);
    }
  };

  const handleNewMessage = () => {
    if (!isChatVisible) {
      setHasNewMessage(true);
    }
  };

  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('login');
    } catch {
      setError('Failed to log out');
    }
  }
  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header
          isUnderTheHoodVisible={isUnderTheHoodVisible}
          toggleUnderTheHood={() => setIsUnderTheHoodVisible(!isUnderTheHoodVisible)}
          isChatVisible={isChatVisible}
          toggleChat={handleChatToggle}
          onLogout={handleLogout}
          currentUser={currentUser}
          hasNewMessage={hasNewMessage}
        />
      </div>
      {error && <Alert message={error} />}
      <main
        className="container mx-auto p-4 flex-grow flex flex-col"
        style={{ paddingTop: '75px' }}
      >
        <div className="flex flex-col h-full space-y-4">
          <div
            className={`flex space-x-4 transition-all duration-300 ease-in-out ${isUnderTheHoodVisible ? 'h-1/2' : 'h-full'}`}
          >
            <div
              className={`transition-all duration-300 ease-in-out ${isChatVisible ? 'w-2/3' : 'w-full'}`}
            >
              <Dashboard currentWeek={currentWeek} setCurrentWeek={setCurrentWeek} />
            </div>
            <div
              className={`transition-all duration-300 ease-in-out ${
                isChatVisible
                  ? 'opacity-100 visible w-1/3 max-h-[50vh] h-full overflow-hidden'
                  : 'opacity-0 invisible w-0 h-0 max-h-0'
              }`}
            >
              <Chatbox onNewMessage={handleNewMessage} currentWeek={currentWeek} />
            </div>
          </div>
          <div
            className={`h-1/2 w-full transition-all duration-300 ease-in-out ${isUnderTheHoodVisible ? 'opacity-100 visible' : 'opacity-0 invisible h-0'}`}
          >
            <UnderTheHood />
          </div>
        </div>
      </main>
    </div>
  );
}
