import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Button } from '../components/ui/button';
import { useAuth } from '../contexts/AuthContext';
import LessonSelector from '../components/LessonSelector';
import { gradeOptions, subjectOptions } from '../constants';
import { validatePhoneNumber } from '../utils/validation';

export default function AddTeacherModal({ onClose, onTeacherAdded }) {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    grades_taught: [],
    last_lesson_source: '',
    last_lesson_id: '',
    phone_number: {
      phone_number: ''
    },
    subjects_taught: []
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      phone_number: {
        phone_number: value
      }
    }));
  };

  const handleMultiSelectChange = (e, field) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [field]: prevData[field].includes(value)
        ? prevData[field].filter((item) => item !== value)
        : [...prevData[field], value]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    console.log('Handling submit!');

    const validationError = validatePhoneNumber(formData.phone_number.phone_number);
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    try {
      const token = await currentUser.getIdToken();
      await axios.post(`${process.env.REACT_APP_API_URL}/teacher/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      onTeacherAdded();
      onClose();
    } catch {
      setError('Failed to create teacher');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center z-50 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[90vh] overflow-hidden my-8">
        <div className="flex flex-col h-full">
          <div className="p-6 flex-grow overflow-y-auto space-y-4">
            <h2 className="text-2xl font-semibold mb-4">Add a New Teacher</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}

            {/* Scrollable form content */}
            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                placeholder="First Name"
                className="w-full p-2 border rounded"
                required
              />
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                className="w-full p-2 border rounded"
                required
              />

              {/* Grades Taught Multi-Select */}
              <div>
                <label className="block mb-1 font-semibold">Grades Taught</label>
                <div className="flex flex-wrap gap-2">
                  {gradeOptions.map((grade) => (
                    <label key={grade} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={grade}
                        checked={formData.grades_taught.includes(grade)}
                        onChange={(e) => handleMultiSelectChange(e, 'grades_taught')}
                        className="form-checkbox"
                      />
                      <span>{grade}</span>
                    </label>
                  ))}
                </div>
              </div>

              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number.phone_number}
                onChange={handlePhoneNumberChange}
                placeholder="Phone Number"
                className="w-full p-2 border rounded"
                required
              />

              {/* Subjects Taught Multi-Select */}
              <div>
                <label className="block mb-1 font-semibold">Subjects Taught</label>
                <div className="flex flex-wrap gap-2">
                  {subjectOptions.map((subject) => (
                    <label key={subject} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={subject}
                        checked={formData.subjects_taught.includes(subject)}
                        onChange={(e) => handleMultiSelectChange(e, 'subjects_taught')}
                        className="form-checkbox"
                      />
                      <span>{subject}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Lesson Source Dropdown */}
              <div>
                <label className="block mb-1 font-semibold">Lesson Source</label>
                <select
                  name="last_lesson_source"
                  value={formData.last_lesson_source}
                  onChange={handleChange}
                  className="w-full p-2 border rounded"
                  required
                >
                  <option value="" disabled>
                    Select a lesson source
                  </option>
                  <option value="illustrative_mathematics">Illustrative Mathematics</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {/* Lesson Selector */}
              {formData.last_lesson_source && (
                <LessonSelector
                  lessonSource={formData.last_lesson_source}
                  onLessonChange={(selectedLesson) =>
                    setFormData((prev) => ({
                      ...prev,
                      last_lesson_id: selectedLesson
                    }))
                  }
                />
              )}
              {/* Footer with buttons */}
              <div className="p-6 flex justify-end space-x-2">
                <Button type="button" onClick={onClose} className="bg-gray-300">
                  Cancel
                </Button>
                <Button type="submit" disabled={loading} className="bg-blue-500 text-white">
                  {loading ? 'Adding...' : 'Add Teacher'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

AddTeacherModal.propTypes = {
  onClose: PropTypes.func,
  onTeacherAdded: PropTypes.func
};
