import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '../components/ui/button';
import LessonSelector from '../components/LessonSelector';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../components/ui/dialog';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '../components/ui/table';
import { gradeOptions, subjectOptions } from '../constants';

export default function TeacherTable({ teachers, error, onRefresh }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  const fetchLessonsBySource = async (lessonSource) => {
    if (!lessonSource) {
      setLessons([]);
      return;
    }

    try {
      const token = await currentUser.getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/lesson/${lessonSource}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setLessons(response.data);
    } catch (err) {
      console.error('Failed to fetch lessons:', err);
    }
  };

  const openEditModal = (teacher) => {
    setEditingData({
      ...teacher,
      phone_number: teacher.phone_number.phone_number,
      grades_taught: teacher.grades_taught || [],
      subjects_taught: teacher.subjects_taught || [],
      last_lesson_id: teacher.last_lesson_id || null,
      active: teacher.active // Include the active field
    });
    setIsModalOpen(true);
  };

  const closeEditModal = () => {
    setEditingData(null);
    setIsModalOpen(false);
  };

  const handleRowClick = (teacher) => {
    openEditModal(teacher);
  };

  // Handle changes for text inputs and boolean values
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setEditingData((prev) => {
      const updatedData = {
        ...prev,
        [name]: type === 'checkbox' ? checked : value // Update the field normally
      };

      // If the lesson source changes, reset the lesson ID
      if (name === 'last_lesson_source') {
        updatedData.last_lesson_id = null; // Reset the lesson ID
      }

      return updatedData;
    });
  };

  // Handle changes for multi-select checkboxes
  const handleMultiSelectChange = (e, field) => {
    const value = e.target.value;
    setEditingData((prevData) => ({
      ...prevData,
      [field]: prevData[field].includes(value)
        ? prevData[field].filter((item) => item !== value)
        : [...prevData[field], value]
    }));
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const token = await currentUser.getIdToken();
      // Prepare data to send to the backend
      const updatedData = {
        ...editingData,
        phone_number: { phone_number: editingData.phone_number },
        last_lesson_source: editingData.last_lesson_source
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/teacher/${editingData.id}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      closeEditModal();
      onRefresh(); // Trigger refresh
    } catch (err) {
      console.error('Error updating teacher:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

      {teachers.length === 0 ? (
        <p className="text-center text-gray-800">No teacher data available.</p>
      ) : (
        <div className="bg-white bg-opacity-30 rounded-lg overflow-hidden shadow-lg">
          <Table className="w-full">
            <TableHeader>
              <TableRow className="bg-gray-200">
                <TableHead className="font-semibold text-left p-4 text-gray-800">
                  First Name
                </TableHead>
                <TableHead className="font-semibold text-left p-4 text-gray-800">
                  Last Name
                </TableHead>
                <TableHead className="font-semibold text-left p-4 text-gray-800">
                  Grades Taught
                </TableHead>
                <TableHead className="font-semibold text-left p-4 text-gray-800">
                  Phone Number
                </TableHead>
                <TableHead className="font-semibold text-left p-4 text-gray-800">
                  Subjects Taught
                </TableHead>
                <TableHead className="font-semibold text-left p-4 text-gray-800">
                  Last Lesson Taught
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {teachers.map((teacher) => (
                <TableRow
                  key={teacher.id}
                  className={`border-b border-gray-300 hover:bg-gray-100 ${
                    !teacher.active ? 'opacity-50' : '' // Gray out inactive teachers
                  }`}
                  onClick={() => handleRowClick(teacher)}
                >
                  <TableCell className="p-4 text-gray-800">{teacher.first_name}</TableCell>
                  <TableCell className="p-4 text-gray-800">{teacher.last_name}</TableCell>
                  <TableCell className="p-4 text-gray-800">
                    {teacher.grades_taught.join(', ')}
                  </TableCell>
                  <TableCell className="p-4 text-gray-800">
                    {teacher.phone_number.phone_number}
                  </TableCell>
                  <TableCell className="p-4 text-gray-800">
                    {teacher.subjects_taught.join(', ')}
                  </TableCell>
                  <TableCell className="p-4 text-gray-800">
                    {teacher.last_lesson ? teacher.last_lesson.display_name : 'No lesson'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {isModalOpen && editingData && (
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                Edit Teacher: {editingData.first_name} {editingData.last_name}{' '}
              </DialogTitle>
            </DialogHeader>
            <div className="space-y-4 overflow-y-auto max-h-[70vh] p-4">
              {/* Active Status */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Active</label>
                <input
                  type="checkbox"
                  name="active"
                  checked={editingData.active}
                  onChange={handleChange}
                  className="form-checkbox"
                />
              </div>

              {/* Grades Taught */}
              <div>
                <label className="block mb-1 font-semibold">Grades Taught</label>
                <div className="flex flex-wrap gap-2">
                  {gradeOptions.map((grade) => (
                    <label key={grade} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={grade}
                        checked={editingData.grades_taught.includes(grade)}
                        onChange={(e) => handleMultiSelectChange(e, 'grades_taught')}
                        className="form-checkbox"
                      />
                      <span>{grade}</span>
                    </label>
                  ))}
                </div>
              </div>

              {/* Phone Number */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="text"
                  name="phone_number"
                  value={editingData.phone_number}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>

              {/* Subjects Taught */}
              <div>
                <label className="block mb-1 font-semibold">Subjects Taught</label>
                <div className="flex flex-wrap gap-2">
                  {subjectOptions.map((subject) => (
                    <label key={subject} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        value={subject}
                        checked={editingData.subjects_taught.includes(subject)}
                        onChange={(e) => handleMultiSelectChange(e, 'subjects_taught')}
                        className="form-checkbox"
                      />
                      <span>{subject}</span>
                    </label>
                  ))}
                </div>
              </div>
              {/* Last Lesson Source Dropdown */}
              <div>
                <label className="block mb-1 font-semibold">Lesson Source</label>
                <select
                  name="last_lesson_source"
                  value={editingData.last_lesson_source || ''}
                  onChange={(e) => {
                    handleChange(e);
                    fetchLessonsBySource(e.target.value);
                  }}
                  className="w-full p-2 border rounded"
                  required
                >
                  <option value="" disabled>
                    Select a lesson source
                  </option>
                  <option value="illustrative_mathematics">Illustrative Mathematics</option>
                  <option value="custom">Custom</option>
                </select>
              </div>

              {/* Last Lesson Dropdown */}
              <div>
                <LessonSelector
                  lessonSource={editingData.last_lesson_source}
                  onLessonChange={(selectedLesson) => {
                    setEditingData((prev) => ({
                      ...prev,
                      last_lesson_id: selectedLesson // Update the selected lesson ID
                    }));
                  }}
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={closeEditModal}>Cancel</Button>
              <Button onClick={handleSave} disabled={loading}>
                {loading ? 'Saving...' : 'Save'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

TeacherTable.propTypes = {
  teachers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      grades_taught: PropTypes.arrayOf(PropTypes.string),
      phone_number: PropTypes.shape({
        phone_number: PropTypes.string
      }),
      subjects_taught: PropTypes.arrayOf(PropTypes.string),
      last_lesson_id: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  error: PropTypes.string,
  onRefresh: PropTypes.func
};
