import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '../components/ui/table';
import { Card, CardContent, CardHeader } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { ChevronLeft, ChevronRight, Loader } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../components/ui/dialog';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

const Dashboard = ({ currentWeek, setCurrentWeek }) => {
  const [teachers, setTeachers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [podcastScript, setPodcastScript] = useState('');
  const [isLoadingScript, setIsLoadingScript] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedScript, setEditedScript] = useState('');
  const [podcastAudioUrl, setPodcastAudioUrl] = useState('');
  const [showSendToPodcastTeam, setShowSendToPodcastTeam] = useState(false);
  const { currentUser } = useAuth();

  // New state variables for editing teacher data
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [teacherToEdit, setTeacherToEdit] = useState(null);
  const [editedActionSteps, setEditedActionSteps] = useState('');
  const [editedObservationNotes, setEditedObservationNotes] = useState('');
  const [editedExitTicketData, setEditedExitTicketData] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const getWeekStart = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedScript(podcastScript);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedScript('');
  };

  const handleSave = async () => {
    setIsLoadingScript(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/podcast/${selectedTeacher.teacher_id}/${currentWeek.toISOString().split('T')[0]}/script`,
        {
          script_content: editedScript
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        }
      );
      setPodcastScript(editedScript);
      setIsEditing(false);
      setShowSendToPodcastTeam(true);
      // Refresh teacher data to reflect the 'update_flag' status
      await fetchTeacherData(currentWeek);
    } catch (error) {
      console.error('Error saving edited script:', error);
      alert('Failed to save edited script. Please try again.');
    } finally {
      setIsLoadingScript(false);
    }
  };

  const handleSendToPodcastTeam = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/podcast/${selectedTeacher.teacher_id}/${currentWeek.toISOString().split('T')[0]}/audio`,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        }
      );
      alert('New script sent to podcast team successfully!');
      setShowSendToPodcastTeam(false);
      // Refresh teacher data
      await fetchTeacherData(currentWeek);
    } catch (error) {
      console.error('Error sending script to podcast team:', error);
      alert('Failed to send script to podcast team. Please try again.');
    }
  };

  const fetchPodcastContent = async (teacher) => {
    setIsLoadingScript(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/podcast/${teacher.teacher_id}/${currentWeek.toISOString().split('T')[0]}?file_types=script&file_types=audio`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        }
      );
      setPodcastScript(response.data.script_content);
      setPodcastAudioUrl(response.data.audio_url);
    } catch (error) {
      console.error('Error fetching podcast content:', error);
      setPodcastScript('Failed to load podcast script.');
      setPodcastAudioUrl('');
    } finally {
      setIsLoadingScript(false);
    }
  };

  const navigateWeek = (direction) => {
    setCurrentWeek((prevWeek) => {
      const newWeek = new Date(prevWeek);
      newWeek.setDate(newWeek.getDate() + direction * 7);
      return getWeekStart(newWeek); // Ensure we always set to the start of the week
    });
  };

  const isCurrentWeek = (date) => {
    const today = new Date();
    const weekStart = getWeekStart(date);
    const currentWeekStart = getWeekStart(today);
    return weekStart.getTime() === currentWeekStart.getTime();
  };

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    };
    return new Date(date).toLocaleDateString('en-US', options);
  };

  useEffect(() => {
    fetchTeacherData(currentWeek);
  }, [currentWeek]);

  const fetchTeacherData = async (week) => {
    setIsLoading(true);
    try {
      const formattedDate = week.toISOString().split('T')[0];
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teacher/data?date_req=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        }
      );
      const formattedData = response.data.map((teacher) => ({
        ...teacher,
        id: teacher.id,
        currentActionSteps: teacher.action_steps.join(', '),
        recentObservationNotes: teacher.observation_notes,
        etdata: teacher.etdata ? teacher.etdata : null,
        coachId: teacher.coach_id,
        teacherId: teacher.teacher_id,
        podcastStatus: teacher.podcast,
        checkinsStatus: teacher.scheduled_texts,
        practiceStatus: teacher.practice_session,
        name: `${teacher.first_name} ${teacher.last_name}`
      }));
      setTeachers(formattedData);
    } catch (error) {
      console.error('Error fetching teacher data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = async (action, teacher) => {
    const buttonState = getButtonState(action, teacher);
    if (buttonState === 'activate team') {
      try {
        // Ensure we're using the Monday of the current week
        const weekStart = getWeekStart(currentWeek);
        const formattedDate = weekStart.toISOString().split('T')[0];
        await axios.post(
          `${process.env.REACT_APP_API_URL}/teacher/${teacher.teacher_id}/activate-${action}`,
          {
            week_start: formattedDate
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`
            }
          }
        );
        // Refresh the teacher data after activation
        await fetchTeacherData(currentWeek);
      } catch (error) {
        console.error(`Error activating ${action} team for ${teacher.name}:`, error);
      }
    } else if (buttonState === 'review' || buttonState === 'update support') {
      setSelectedTeacher(teacher);
      setSelectedAction(action);
      setIsModalOpen(true);
      if (action === 'podcast') {
        fetchPodcastContent(teacher);
      }
      setShowSendToPodcastTeam(buttonState === 'update support');
    }
  };

  const getButtonState = (type, teacher) => {
    const status = teacher[`${type}Status`];
    switch (status) {
      case 'none':
        return 'activate team';
      case 'in_progress':
        return 'generating';
      case 'completed':
        return 'review';
      case 'update_flag':
        return 'update support';
      default:
        return 'activate team';
    }
  };

  const getButtonStyle = (type, teacher) => {
    const state = getButtonState(type, teacher);
    switch (state) {
      case 'activate team':
        return 'bg-blue-400 bg-opacity-80 hover:bg-opacity-100 text-white';
      case 'generating':
        return 'bg-yellow-400 bg-opacity-80 text-white cursor-not-allowed';
      case 'review':
        return 'bg-green-400 bg-opacity-80 hover:bg-opacity-100 text-white';
      case 'update support':
        return 'bg-purple-400 bg-opacity-80 hover:bg-opacity-100 text-white';
      default:
        return 'bg-gray-400 bg-opacity-80 text-white';
    }
  };

  const getModalContent = () => {
    if (!selectedTeacher || !selectedAction) return { title: '', content: '' };

    let content = '';
    switch (selectedAction) {
      case 'podcast':
        if (isLoadingScript) {
          content = 'Loading podcast script...';
        } else if (podcastScript) {
          content = podcastScript;
        } else {
          content = 'Podcast script not available';
        }
        break;
      case 'checkins':
        content = selectedTeacher.scheduled_texts || 'Check-ins not available';
        break;
      case 'practice':
        content = selectedTeacher.practice_session || 'Practice session not available';
        break;
      default:
        content = 'No content available';
    }

    return {
      title: `${selectedAction.charAt(0).toUpperCase() + selectedAction.slice(1)} for ${selectedTeacher.name}`,
      content: content
    };
  };

  // Handle row click to open edit modal
  const handleRowClick = (teacher) => {
    setTeacherToEdit(teacher);
    setIsEditModalOpen(true);
  };

  // Update local state when teacherToEdit changes
  useEffect(() => {
    if (teacherToEdit) {
      setEditedActionSteps(teacherToEdit.currentActionSteps || '');
      setEditedObservationNotes(teacherToEdit.recentObservationNotes || '');
      setEditedExitTicketData(teacherToEdit.etdata ? teacherToEdit.etdata.join(', ') : '');
    }
  }, [teacherToEdit]);

  // Save edited teacher data
  const handleSaveEdit = async (teacher) => {
    setIsSaving(true);
    try {
      const updatedEtdata = editedExitTicketData
        ? editedExitTicketData
            .split(',')
            .map((s) => parseFloat(s.trim()))
            .filter((num) => !isNaN(num))
        : [];

      if (updatedEtdata || editedActionSteps || editedObservationNotes) {
        // Prepare the payload
        const updatedTeacherData = {
          id: teacher.id,
          week_start: teacher.week_start,
          action_steps: editedActionSteps
            ? editedActionSteps.split(',').map((step) => step.trim())
            : teacher.action_steps,
          addl_context: teacher.addl_context,
          observation_notes: editedObservationNotes || teacher.recentObservationNotes,
          etdata: updatedEtdata ? updatedEtdata : teacher.etdata,
          coach_id: teacher.coachId,
          teacher_id: teacher.teacherId,
          podcast: teacher.podcastStatus,
          practice_session: teacher.practiceStatus,
          scheduled_texts: teacher.checkinsStatus
        };

        await axios.put(
          `${process.env.REACT_APP_API_URL}/teacher/${teacher.teacher_id}/data`,
          updatedTeacherData,
          {
            headers: {
              Authorization: `Bearer ${currentUser.accessToken}`
            }
          }
        );
      }
      setIsEditModalOpen(false);

      // Refresh teacher data
      await fetchTeacherData(currentWeek);
    } catch (error) {
      console.error('Error updating teacher data:', error);
      alert('Failed to update teacher data. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="relative z-0 mb-4">
      {/* Frosted glass background */}
      <div className="absolute inset-0 bg-white bg-opacity-30 backdrop-filter backdrop-blur-md rounded-lg"></div>

      {/* Main content with inset shadow */}
      <div
        className="relative bg-transparent rounded-lg overflow-hidden"
        style={{
          boxShadow: 'inset 0 0 15px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(255, 255, 255, 0.1)'
        }}
      >
        {/* Header */}
        <div className="bg-gray-50 bg-opacity-50 border-b border-gray-200 border-opacity-50 p-4">
          <h2 className="text-2xl font-light text-gray-800">Teacher Support Tracker</h2>
          <p className="text font-light text-gray-400">view and generate teacher supports</p>
        </div>

        {/* Content */}
        <div className="p-4">
          <div className="flex justify-center items-center mb-4">
            <Button
              onClick={() => navigateWeek(-1)}
              className="mr-2 bg-transparent hover:bg-green-200"
            >
              <ChevronLeft size={24} />
            </Button>
            <div className={`relative ${isCurrentWeek(currentWeek) ? 'font-bold' : ''}`}>
              <h2 className="text-xl font-semibold text-gray-800">
                Week of {formatDate(getWeekStart(currentWeek))}
              </h2>
              {isCurrentWeek(currentWeek) && (
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-green-500 rounded-full"></div>
              )}
            </div>
            <Button
              onClick={() => navigateWeek(1)}
              className="ml-2 bg-transparent hover:bg-green-200"
            >
              <ChevronRight size={24} />
            </Button>
          </div>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Loader className="animate-spin mr-2" size={24} />
              <span className="text-gray-800">Loading teacher data...</span>
            </div>
          ) : (
            <div className="bg-white bg-opacity-30 rounded-lg overflow-hidden">
              <Table className="w-full">
                <TableHeader>
                  <TableRow className="bg-gray-100 bg-opacity-50">
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Teacher Name
                    </TableHead>
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Current Action Steps
                    </TableHead>
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Most Recent Observation Notes
                    </TableHead>
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Weekly Podcast
                    </TableHead>
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Text Check-ins
                    </TableHead>
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Practice Session
                    </TableHead>
                    <TableHead className="font-semibold text-left p-2 text-gray-800">
                      Exit Ticket Data
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {teachers.map((teacher) => (
                    <TableRow
                      key={teacher.teacherDataID}
                      className="border-b border-gray-200 border-opacity-50 hover:bg-white hover:bg-opacity-20"
                      onClick={() => handleRowClick(teacher)}
                    >
                      <TableCell className="p-2 text-gray-800">{teacher.name}</TableCell>
                      <TableCell className="p-2 text-gray-800">
                        {teacher.currentActionSteps}
                      </TableCell>
                      <TableCell className="p-2 text-gray-800">
                        {teacher.recentObservationNotes}
                      </TableCell>
                      {['podcast', 'checkins', 'practice'].map((action) => (
                        <TableCell
                          key={action}
                          className="p-2"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button
                            onClick={() => handleButtonClick(action, teacher)}
                            className={`font-bold py-1 px-2 rounded ${getButtonStyle(
                              action,
                              teacher
                            )} ${
                              getButtonState(action, teacher) === 'generating'
                                ? 'opacity-50 cursor-not-allowed'
                                : ''
                            }`}
                            disabled={getButtonState(action, teacher) === 'generating'}
                          >
                            {getButtonState(action, teacher) === 'generating' ? (
                              <>
                                <Loader className="animate-spin mr-2" size={16} />
                                Generating...
                              </>
                            ) : (
                              getButtonState(action, teacher)
                            )}
                          </Button>
                        </TableCell>
                      ))}
                      <TableCell className="p-2 text-gray-800">
                        {teacher.etdata ? teacher.etdata.join(', ') : 'N/A'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>

      {/* Modal for viewing and editing podcast script */}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-3xl max-h-[80vh] flex flex-col">
          <DialogHeader>
            <DialogTitle>{getModalContent().title}</DialogTitle>
          </DialogHeader>
          <Card className="flex-grow overflow-hidden">
            <CardHeader>
              <h3 className="text-lg font-semibold">Details</h3>
              {podcastAudioUrl && (
                <audio controls className="w-full mt-2">
                  <source src={podcastAudioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              )}
            </CardHeader>
            <CardContent className="overflow-y-auto max-h-[50vh]">
              <DialogDescription>
                {isLoadingScript ? (
                  <div className="flex items-center">
                    <Loader className="animate-spin mr-2" size={16} />
                    Loading podcast content...
                  </div>
                ) : isEditing ? (
                  <textarea
                    value={editedScript}
                    onChange={(e) => setEditedScript(e.target.value)}
                    className="w-full h-full min-h-[300px] p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                ) : (
                  <pre className="whitespace-pre-wrap">{getModalContent().content}</pre>
                )}
              </DialogDescription>
            </CardContent>
          </Card>
          <DialogFooter>
            {!isEditing && (
              <>
                <Button onClick={handleEdit} disabled={isLoadingScript}>
                  Edit
                </Button>
                {showSendToPodcastTeam && (
                  <Button
                    onClick={handleSendToPodcastTeam}
                    className="bg-green-500 hover:bg-green-600 text-white"
                  >
                    Send New Script to Podcast Team
                  </Button>
                )}
              </>
            )}
            {isEditing && (
              <>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Modal for editing teacher data */}
      {isEditModalOpen && (
        <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Teacher Data</DialogTitle>
              <DialogDescription>
                Editing data for {teacherToEdit ? teacherToEdit.name : ''}
              </DialogDescription>
            </DialogHeader>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Current Action Steps
                </label>
                <textarea
                  value={editedActionSteps}
                  onChange={(e) => setEditedActionSteps(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  rows={3}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Most Recent Observation Notes
                </label>
                <textarea
                  value={editedObservationNotes}
                  onChange={(e) => setEditedObservationNotes(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  rows={3}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Exit Ticket Data</label>
                <textarea
                  value={editedExitTicketData}
                  onChange={(e) => setEditedExitTicketData(e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  rows={3}
                />
              </div>
            </div>
            <DialogFooter>
              <Button onClick={() => setIsEditModalOpen(false)} disabled={isSaving}>
                Cancel
              </Button>
              <Button onClick={() => handleSaveEdit(teacherToEdit)} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  currentWeek: PropTypes.instanceOf(Date),
  setCurrentWeek: PropTypes.func
};

export default Dashboard;
