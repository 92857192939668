import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import React from 'react';

import { AuthProvider } from './contexts/AuthContext';
import ConfigDashboard from './components/ConfigDashboard';
import FirebaseRoute from './components/FirebaseRoute';
import GoogleSignup from './components/GoogleSignup';
import HomePage from './components/HomePage';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Signup from './components/Signup';
import UploadLessonPlans from './components/LessonConfigDashboard';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />

          {/* Firebase routes, require firebase token but no api authorization */}
          <Route element={<FirebaseRoute />}>
            <Route exact path="/google_signup" element={<GoogleSignup />} />
          </Route>
          {/* Private Routes, requiring authentication + api authorization */}
          <Route element={<PrivateRoute />}>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/teacher_config" element={<ConfigDashboard />} />
            <Route exact path="/lesson_config" element={<UploadLessonPlans />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
