import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

// This route requires users to be authenticated with firebase
// and have an associated account in our api.
const PrivateRoute = () => {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(() => {
    // Check if the user is already authorized in local storage/session memory
    return sessionStorage.getItem('isAuthorized') === 'true';
  });

  useEffect(() => {
    const checkAuth = async () => {
      if (isAuthorized) {
        // If already authorized, skip API call
        setIsLoading(false);
        return;
      }

      try {
        // Validate the user with the backend.
        await axios.get(`${process.env.REACT_APP_API_URL}/account/info`, {
          headers: {
            Authorization: `Bearer ${currentUser.accessToken}`
          }
        });

        // Mark user as authorized in session memory.
        setIsAuthorized(true);
        sessionStorage.setItem('isAuthorized', 'true');
      } catch (error) {
        console.error('Authorization failed:', error);
        setIsAuthorized(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [isAuthorized]);

  if (isLoading) return <div>Loading...</div>;

  return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
