import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_URL } from '../config/api_config';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { useAuth } from '../contexts/AuthContext';
import { Alert } from '../components/ui/alert';
import { FormLayout } from './ui/formLayout';

const registerUserWithAPI = async (firstName, lastName, phoneNumber, firebaseToken) => {
  const registerEndpoint = `${API_URL}/account/register`;

  const userData = {
    first_name: firstName,
    last_name: lastName,
    phone_number: {
      phone_number: phoneNumber
    }
  };
  try {
    const response = await fetch(registerEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${firebaseToken}`
      },
      body: JSON.stringify(userData)
    });

    if (!response.ok) {
      throw new Error('Failed to register user');
    }

    return await response.json();
  } catch (error) {
    console.error('Error during API regisration:', error);
    throw error;
  }
};

export default function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { signup, loginWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleGoogleSignup = async () => {
    try {
      const userCredential = await loginWithGoogle();
      await userCredential.user.getIdToken();
      navigate('/google_signup');
    } catch {
      return setError('Unable to signup with google');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      return setError('Passwords do not match');
    }

    if (firstName === '') {
      return setError('Must enter full name');
    }

    try {
      setError('');
      setLoading(true);

      // Sign in with firebase first
      const userCredential = await signup(email, password);
      const token = await userCredential.user.getIdToken();

      // Register the user with API
      await registerUserWithAPI(firstName, lastName, phoneNumber, token);

      // Redirect to dashboard if registration is successful
      navigate('/');
    } catch {
      setError('Failed to create an account');
    }
    setLoading(false);
  };

  return (
    <FormLayout title="Sign Up">
      {error && <Alert message={error} />}
      <form
        onSubmit={handleSubmit}
        className="bg-white bg-opacity-0 rounded-lg p-8 backdrop-filter backdrop-blur-md"
      >
        <div className="space-y-4">
          <Input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
          <Input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
          <Input
            type="text"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
          <Input
            type="password"
            placeholder="Password Confirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
        </div>
        <div className="flex justify-center w-full mt-6">
          <Button
            disabled={loading}
            type="submit"
            variant="ghost"
            className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
          >
            Sign Up
          </Button>
        </div>
      </form>
      <div className="flex justify-center w-full mt-4">
        <Button
          disabled={loading}
          onClick={handleGoogleSignup}
          variant="ghost"
          className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
        >
          Sign Up with Google
        </Button>
      </div>

      <div className="w-100 text-center mt-2">
        Already have an account? <Link to="/login">Login</Link>
      </div>
    </FormLayout>
  );
}
