import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Input } from '../components/ui/input';
import { Button } from '../components/ui/button';
import { FormLayout } from './ui/formLayout';
import { useAuth } from '../contexts/AuthContext';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, loginWithGoogle } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    await loginUser(() => login(email, password));
  };

  const handleGoogleLogin = async () => {
    await loginUser(loginWithGoogle);
  };

  const loginUser = async (loginMethod) => {
    try {
      setError('');
      setLoading(true);

      // Call the login method
      const userCredential = await loginMethod();
      const token = await userCredential.user.getIdToken();

      // Ensure user exists with this Firebase token
      await axios.get(`${process.env.REACT_APP_API_URL}/account/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      navigate('/');
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('No user found with this login');
      } else {
        setError('Failed to sign in');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormLayout title="nisa" subtitle="_controlcenter">
      <form
        onSubmit={handleLogin}
        className="bg-white bg-opacity-0 rounded-lg p-8 backdrop-filter backdrop-blur-md"
      >
        <div className="space-y-4">
          <Input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-6 py-3 bg-white bg-opacity-95 focus:outline-none focus:ring-2 focus:ring-blue-400 shadow-text-entry"
          />
        </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <div className="flex justify-center w-full mt-6">
          <Button
            disabled={loading}
            type="submit"
            variant="ghost"
            className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
          >
            Log In
          </Button>
        </div>
        <div className="flex justify-center w-full mt-4">
          <Button
            disabled={loading}
            onClick={handleGoogleLogin}
            variant="ghost"
            className="w-1/2 text-white font-light rounded-full border border-transparent hover:border-white hover:bg-transparent hover:text-white transition-all duration-200 shadow-submit"
          >
            Sign in with Google
          </Button>
        </div>
      </form>
      <div className="w-100 text-center mt-2">
        Need an account? <Link to="/signup">Sign Up</Link>
      </div>
    </FormLayout>
  );
}
