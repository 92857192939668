import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

const LessonSelector = ({ lessonSource, onLessonChange }) => {
  const [lessons, setLessons] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [modules, setModules] = useState([]);
  const [topics, setTopics] = useState([]);
  const [grades, setGrades] = useState([]);
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedCurriculum, setSelectedCurriculum] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchLessonsBySource = async () => {
      setLessons([]);
      setGrades([]);
      setCurriculums([]);
      setModules([]);
      setTopics([]);
      setFilteredLessons([]);

      try {
        const token = await currentUser.getIdToken();
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/${lessonSource}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        const lessonsData = response.data;
        setLessons(lessonsData);

        // Extract unique grades
        const uniqueGrades = [...new Set(lessonsData.map((lesson) => lesson.grade))];
        setGrades(uniqueGrades);

        // Extract unique curriculums
        const uniqueCurriculums = [
          ...new Set(lessonsData.map((lesson) => lesson.curriculum_name).filter(Boolean))
        ];
        setCurriculums(uniqueCurriculums);
      } catch (err) {
        console.error('Failed to fetch lessons:', err);
      }
    };

    fetchLessonsBySource();
  }, [lessonSource, currentUser]);

  useEffect(() => {
    if (selectedGrade) {
      const curriculumsForGrade = [
        ...new Set(
          lessons
            .filter((lesson) => lesson.grade === selectedGrade)
            .map((lesson) => lesson.curriculum_name)
            .filter(Boolean) // Exclude null or undefined
        )
      ];
      setCurriculums(curriculumsForGrade);

      // If no curriculums are available, reset and proceed to modules
      if (curriculumsForGrade.length === 0) {
        setSelectedCurriculum('');
        updateModules(selectedGrade, null);
      } else {
        setSelectedCurriculum('');
        setModules([]);
      }
    } else {
      setCurriculums([]);
      setModules([]);
      setTopics([]);
      setFilteredLessons([]);
    }
  }, [selectedGrade, lessons]);

  const updateModules = (grade, curriculum) => {
    const modulesForSelection = [
      ...new Set(
        lessons
          .filter(
            (lesson) =>
              lesson.grade === grade && (curriculum ? lesson.curriculum_name === curriculum : true)
          )
          .map((lesson) => lesson.module_name)
      )
    ];
    setModules(modulesForSelection);
    setSelectedModule('');
    setTopics([]);
    setFilteredLessons([]);
  };

  useEffect(() => {
    if (selectedCurriculum || curriculums.length === 0) {
      updateModules(selectedGrade, selectedCurriculum);
    }
  }, [selectedCurriculum, selectedGrade]);

  useEffect(() => {
    if (selectedModule) {
      const topicsForModule = [
        ...new Set(
          lessons
            .filter((lesson) => lesson.module_name === selectedModule)
            .map((lesson) => lesson.topic_name)
        )
      ];
      setTopics(topicsForModule);
      setSelectedTopic('');
      setFilteredLessons([]);
    } else {
      setTopics([]);
      setFilteredLessons([]);
    }
  }, [selectedModule, lessons]);

  useEffect(() => {
    if (selectedTopic) {
      const lessonsForTopic = lessons.filter(
        (lesson) => lesson.module_name === selectedModule && lesson.topic_name === selectedTopic
      );
      setFilteredLessons(lessonsForTopic);
      setSelectedLesson('');
    } else {
      setFilteredLessons([]);
    }
  }, [selectedTopic, selectedModule, lessons]);

  const handleLessonChange = (lessonId) => {
    setSelectedLesson(lessonId);
    if (onLessonChange) {
      onLessonChange(lessonId); // Notify parent about the selected lesson
    }
  };

  return (
    <div>
      <div>
        <label className="block mb-1 font-semibold">Grade</label>
        <select
          name="grade"
          value={selectedGrade}
          onChange={(e) => setSelectedGrade(e.target.value)}
          className="w-full p-2 border rounded"
          required
        >
          <option value="" disabled>
            Select a grade
          </option>
          {grades.map((grade) => (
            <option key={grade} value={grade}>
              {grade}
            </option>
          ))}
        </select>
      </div>

      {curriculums.length > 0 && (
        <div>
          <label className="block mt-4 mb-1 font-semibold">Curriculum</label>
          <select
            name="curriculum"
            value={selectedCurriculum}
            onChange={(e) => setSelectedCurriculum(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="" disabled>
              Select a curriculum
            </option>
            {curriculums.map((curriculum) => (
              <option key={curriculum} value={curriculum}>
                {curriculum}
              </option>
            ))}
          </select>
        </div>
      )}

      <div>
        <label className="block mt-4 mb-1 font-semibold">Module</label>
        <select
          name="module"
          value={selectedModule}
          onChange={(e) => setSelectedModule(e.target.value)}
          className="w-full p-2 border rounded"
          required
        >
          <option value="" disabled>
            Select a module
          </option>
          {modules.map((module) => (
            <option key={module} value={module}>
              {module}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block mt-4 mb-1 font-semibold">Topic</label>
        <select
          name="topic"
          value={selectedTopic}
          onChange={(e) => setSelectedTopic(e.target.value)}
          className="w-full p-2 border rounded"
          disabled={!selectedModule || topics.length === 0}
          required
        >
          <option value="" disabled>
            {selectedModule ? 'Select a topic' : 'Select a module first'}
          </option>
          {topics.map((topic) => (
            <option key={topic} value={topic}>
              {topic}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block mt-4 mb-1 font-semibold">Lesson</label>
        <select
          name="lesson"
          value={selectedLesson}
          onChange={(e) => handleLessonChange(e.target.value)}
          className="w-full p-2 border rounded"
          disabled={!selectedTopic || filteredLessons.length === 0}
          required
        >
          <option value="" disabled>
            {selectedTopic ? 'Select a lesson' : 'Select a topic first'}
          </option>
          {filteredLessons.map((lesson) => (
            <option key={lesson.id} value={lesson.id}>
              {lesson.display_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LessonSelector;
