import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import { Button } from '../components/ui/button';

export default function UploadLessonPlans() {
  const [files, setFiles] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { currentUser, logout } = useAuth();

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    const parsedMetadata = selectedFiles.map((file) => {
      const nameParts = file.name.replace('.pdf', '').split('.');
      return {
        file,
        filename: file.name,
        grade: nameParts[0] || '',
        module: nameParts[1] || '',
        topic: nameParts[2] || '',
        lesson: nameParts[3] ? parseInt(nameParts[3], 10) : ''
      };
    });

    setFiles(selectedFiles);
    setMetadata(parsedMetadata);
  };

  const handleMetadataChange = (index, field, value) => {
    const updatedMetadata = [...metadata];
    updatedMetadata[index][field] = field === 'lesson' ? parseInt(value, 10) || '' : value;
    setMetadata(updatedMetadata);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Append files to FormData
    files.forEach((file) => {
      formData.append('files', file);
    });

    // Serialize the metadata array and append it as a single field
    formData.append(
      'metadata',
      JSON.stringify(
        metadata.map((meta) => ({
          grade: meta.grade,
          module: meta.module,
          topic: meta.topic,
          lesson: meta.lesson,
          filename: meta.filename
        }))
      )
    );

    try {
      setLoading(true);
      setError('');

      const token = await currentUser.getIdToken();

      await axios.post(`${process.env.REACT_APP_API_URL}/lesson/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      alert('Files uploaded successfully!');
      // Clear the form after successful upload
      setFiles([]);
      setMetadata([]);
    } catch (err) {
      const errorDetail = err.response?.data?.detail || err.message || 'An unknown error occurred';
      setError(
        typeof errorDetail === 'string' ? errorDetail : JSON.stringify(errorDetail, null, 2)
      );
      console.error('Upload error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setError('');
    try {
      await logout();
      // Navigate to login page or handle logout
    } catch {
      setError('Failed to log out');
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center relative">
      {/* Backgrounds */}
      <div className="fixed inset-0 z-[-2] bg-radial-gradient bg-fixed" />
      <div className="fixed inset-0 z-[-1] opacity-75 bg-tile-overlay bg-repeat" />

      {/* Header */}
      <div className="fixed top-0 left-0 right-0 z-10">
        <Header
          toggleUnderTheHood={null}
          toggleChat={null}
          onLogout={handleLogout}
          currentUser={currentUser}
          hasNewMessage={false}
        />
      </div>

      <main
        className="container mx-auto p-6 flex-grow flex flex-col items-center"
        style={{ paddingTop: '100px' }}
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-8">Upload Lesson Plans</h1>
        <div className="w-full max-w-5xl bg-white bg-opacity-40 rounded-lg shadow-lg p-6">
          {error && <div className="text-red-500 mb-4">{error}</div>}
          {loading && <div className="text-gray-800 mb-4">Loading...</div>}
          <div className="mb-4">
            <input
              type="file"
              accept="application/pdf"
              multiple
              onChange={handleFileChange}
              className="w-full p-2 border rounded"
            />
          </div>
          {metadata.length > 0 && (
            <div className="overflow-x-auto">
              <table className="w-full mb-4">
                <thead>
                  <tr className="bg-gray-200">
                    <th className="p-2 text-left">File</th>
                    <th className="p-2 text-left">Grade</th>
                    <th className="p-2 text-left">Module</th>
                    <th className="p-2 text-left">Topic</th>
                    <th className="p-2 text-left">Lesson</th>
                  </tr>
                </thead>
                <tbody>
                  {metadata.map((meta, index) => (
                    <tr key={index} className="border-b">
                      <td className="p-2">{meta.file.name}</td>
                      <td className="p-2">
                        <input
                          type="text"
                          value={meta.grade}
                          onChange={(e) => handleMetadataChange(index, 'grade', e.target.value)}
                          className="w-full p-1 border rounded"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          value={meta.module}
                          onChange={(e) => handleMetadataChange(index, 'module', e.target.value)}
                          className="w-full p-1 border rounded"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          value={meta.topic}
                          onChange={(e) => handleMetadataChange(index, 'topic', e.target.value)}
                          className="w-full p-1 border rounded"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="number"
                          value={meta.lesson}
                          onChange={(e) => handleMetadataChange(index, 'lesson', e.target.value)}
                          className="w-full p-1 border rounded"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <div className="flex justify-end">
            <Button
              onClick={handleSubmit}
              disabled={loading || files.length === 0}
              className="bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700"
            >
              {loading ? 'Uploading...' : 'Upload'}
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
}
